import { useEffect, useState, useCallback } from "react"

const useFetch = url => {
  const [data, setData] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState(null)

  const executeFetch = useCallback(
    async abortCont => {
      setIsPending(true)
      setData(null)
      setError(null)
      //await timeout(3000)
      await fetch(url, abortCont ? { signal: abortCont.signal } : {})
        .then(res => {
          if (!res.ok) {
            throw Error(`Impossibile recuperare i dati da ${url}`)
          }
          return res.text()
        })
        .then(data => {
          setError(null)
          setIsPending(false)
          setData(data)
        })
        .catch(err => {
          if (err.name === "AbortError") {
            console.log("Fetch request aborted")
          } else {
            setIsPending(false)
            setError(err.message)
          }
        })
    },
    [url]
  )

  /*const timeout = ms => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }*/

  useEffect(() => {
    const abortCont = new AbortController()
    executeFetch(abortCont)
    return () => abortCont.abort()
  }, [url, executeFetch])

  return {
    data,
    isPending,
    error,
    executeFetch,
  }
}

export default useFetch
