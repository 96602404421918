import React from "react"

import recordCardStyles from "./recordCard.module.scss"

const RecordCard = ({ title, image, records }) => {
  return (
    <div className="scroll-target card shadow mt-2 mb-3">
      <div className={recordCardStyles.imgContainer}>
        <img src={image} className="card-img-top" alt={title} />
        <div
          className={`card-body text-center ${recordCardStyles.cardTitleOverImg}`}
        >
          <h3 className="card-title mb-0">{title}</h3>
        </div>
      </div>
      <ul className="list-group list-group-flush">
        {!records || records.length <= 0 ? (
          <li className="list-group-item">Nessun dato trovato</li>
        ) : (
          records.map((record, index) => (
            <li key={index} className="list-group-item">
              <div className="row align-items-center">
                <div className="col-6">{record.title}</div>
                <div className="col-6 text-right">
                  <h5>{record.value}</h5>
                  <div>
                    <em>{record.time}</em>
                  </div>
                </div>
              </div>
            </li>
          ))
        )}
      </ul>
    </div>
  )
}

export default RecordCard
