import moment from "moment"
import "moment/locale/it"

const startingDate = new Date("2019-06-13")
const dateTimeFormat = "DD MMMM YYYY [ore] HH:mm"
const dateFormat = "DD MMMM YYYY"
const monthFormat = "MMMM YYYY"
const toDateFormat = "[Fino al] DD MMMM YYYY"

const months = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
]

moment.defaultFormat = dateTimeFormat

export const getStartingDate = () => moment(startingDate).format(dateFormat)

const capitalize = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getMonths = () => {
  try {
    let availableMonths = []
    let today = new Date()
    let skipPreviousMonths =
      today.getFullYear() === startingDate.getFullYear() ? true : false
    let currentMonth = today.getMonth()
    let startMonth = skipPreviousMonths ? startingDate.getMonth() : 0
    for (let i = startMonth; i <= currentMonth; i++) {
      availableMonths.push({ index: i, name: months[i] })
    }
    return availableMonths
  } catch (e) {
    console.error("Error initializing months: ", e)
    return null
  }
}

const parseINIString = data => {
  let regex = {
    section: /^\s*\[\s*([^\]]*)\s*\]\s*$/,
    param: /^\s*([^=]+?)\s*=\s*(.*?)\s*$/,
    comment: /^\s*;.*$/,
  }
  let value = {}
  let lines = data.split(/[\r\n]+/)
  let section = null
  lines.forEach(function (line) {
    if (regex.comment.test(line)) {
      return
    } else if (regex.param.test(line)) {
      let match = line.match(regex.param)
      if (section) {
        value[section][match[1]] = match[2]
      } else {
        value[match[1]] = match[2]
      }
    } else if (regex.section.test(line)) {
      let match = line.match(regex.section)
      value[match[1]] = {}
      section = match[1]
    } else if (line.length === 0 && section) {
      section = null
    }
  })
  return value
}

const getTemperatureRecords = values => {
  var data = []
  try {
    data.push({
      title: "Temperatura massima",
      value: parseFloat(values.Temperature.hightempvalue).toFixed(1) + " °C",
      time: moment(values.Temperature.hightemptime).format(),
    })
    data.push({
      title: "Temperatura minima",
      value: parseFloat(values.Temperature.lowtempvalue).toFixed(1) + " °C",
      time: moment(values.Temperature.lowtemptime).format(),
    })
    data.push({
      title: "Temperatura apparente massima",
      value: parseFloat(values.Temperature.highapptempvalue).toFixed(1) + " °C",
      time: moment(values.Temperature.highapptemptime).format(),
    })
    data.push({
      title: "Temperatura apparente minima",
      value: parseFloat(values.Temperature.lowapptempvalue).toFixed(1) + " °C",
      time: moment(values.Temperature.lowapptemptime).format(),
    })
    data.push({
      title: "Punto di rugiada massimo",
      value:
        parseFloat(values.Temperature.highdewpointvalue).toFixed(1) + " °C",
      time: moment(values.Temperature.highdewpointtime).format(),
    })
    data.push({
      title: "Punto di rugiada minimo",
      value: parseFloat(values.Temperature.lowdewpointvalue).toFixed(1) + " °C",
      time: moment(values.Temperature.lowdewpointtime).format(),
    })
    data.push({
      title: "Temperatura percepita minima",
      value: parseFloat(values.Temperature.lowchillvalue).toFixed(1) + " °C",
      time: moment(values.Temperature.lowchilltime).format(),
    })
    data.push({
      title: "Indice di calore massimo",
      value:
        parseFloat(values.Temperature.highheatindexvalue).toFixed(1) + " °C",
      time: moment(values.Temperature.highheatindextime).format(),
    })
    data.push({
      title: "Temperatura minima più alta",
      value: parseFloat(values.Temperature.highmintempvalue).toFixed(1) + " °C",
      time: moment(values.Temperature.highmintemptime).format(),
    })
    data.push({
      title: "Temperatura massima più bassa",
      value: parseFloat(values.Temperature.lowmaxtempvalue).toFixed(1) + " °C",
      time: moment(values.Temperature.lowmaxtemptime).format(),
    })
    data.push({
      title: "Umidità massima",
      value: values.Humidity.highhumidityvalue + " %",
      time: moment(values.Humidity.highhumiditytime).format(),
    })
    data.push({
      title: "Umidità minima",
      value: values.Humidity.lowhumidityvalue + " %",
      time: moment(values.Humidity.lowhumiditytime).format(),
    })
    data.push({
      title: "Range giornaliero massimo",
      value:
        parseFloat(values.Temperature.hightemprangevalue).toFixed(1) + " °C",
      time: moment(values.Temperature.hightemprangetime).format(),
    })
    data.push({
      title: "Range giornaliero minimo",
      value:
        parseFloat(values.Temperature.lowtemprangevalue).toFixed(1) + " °C",
      time: moment(values.Temperature.lowtemprangetime).format(),
    })
    return data
  } catch (e) {
    return []
  }
}

const getRainRecords = values => {
  try {
    let data = []
    data.push({
      title: "Massima intensità di pioggia",
      value: parseFloat(values.Rain.highrainratevalue).toFixed(1) + " mm/h",
      time: moment(values.Rain.highrainratetime).format(),
    })
    data.push({
      title: "Massima piovosità oraria",
      value: parseFloat(values.Rain.highhourlyrainvalue).toFixed(1) + " mm",
      time: moment(values.Rain.highhourlyraintime).format(),
    })
    data.push({
      title: "Massima piovosità giornaliera",
      value: parseFloat(values.Rain.highdailyrainvalue).toFixed(1) + " mm",
      time: moment(values.Rain.highdailyraintime).format(dateFormat),
    })
    data.push({
      title: "Massima piovosità mensile",
      value: parseFloat(values.Rain.highmonthlyrainvalue).toFixed(1) + " mm",
      time: capitalize(
        moment(values.Rain.highmonthlyraintime).format(monthFormat)
      ),
    })
    data.push({
      title: "Periodo di asciutto più lungo",
      value: values.Rain.longestdryperiodvalue + " giorni",
      time: moment(values.Rain.longestdryperiodtime).format(toDateFormat),
    })
    data.push({
      title: "Periodo di pioggia più lungo",
      value: values.Rain.longestwetperiodvalue + " giorni",
      time: moment(values.Rain.longestwetperiodtime).format(toDateFormat),
    })
    return data
  } catch (e) {
    return []
  }
}

const getWindRecords = values => {
  try {
    let data = []
    data.push({
      title: "Raffica massima",
      value: parseFloat(values.Wind.highgustvalue).toFixed(1) + " km/h",
      time: moment(values.Wind.highgusttime).format(),
    })
    data.push({
      title: "Massima velocità media",
      value: parseFloat(values.Wind.highwindvalue).toFixed(1) + " km/h",
      time: moment(values.Wind.highwindtime).format(),
    })
    data.push({
      title: "Massima distanza giornaliera",
      value: parseFloat(values.Wind.highdailywindrunvalue).toFixed(1) + " km",
      time: moment(values.Wind.highdailywindruntime).format(dateFormat),
    })
    return data
  } catch (e) {
    return []
  }
}

const getPressureRecords = values => {
  try {
    let data = []
    data.push({
      title: "Pressione massima",
      value: parseFloat(values.Pressure.highpressurevalue).toFixed(1) + " hPa",
      time: moment(values.Pressure.highpressuretime).format(),
    })
    data.push({
      title: "Pressione minima",
      value: parseFloat(values.Pressure.lowpressurevalue).toFixed(1) + " hPa",
      time: moment(values.Pressure.lowpressuretime).format(),
    })
    return data
  } catch (e) {
    return []
  }
}

export const getDataFromINIString = (iniString, month) => {
  try {
    let values = parseINIString(iniString)

    if (month !== undefined) {
      month = ("0" + (month + 1)).slice(-2)
      values = {
        Temperature: values["Temperature" + month],
        Humidity: values["Humidity" + month],
        Rain: values["Rain" + month],
        Wind: values["Wind" + month],
        Pressure: values["Pressure" + month],
      }
    }
    let data = {
      temperature: getTemperatureRecords(values),
      rain: getRainRecords(values),
      wind: getWindRecords(values),
      pressure: getPressureRecords(values),
    }

    return data
  } catch (e) {
    console.error("Error parsing data from INI string: ", e)
    return null
  }
}
