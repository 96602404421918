import React, { useState, useEffect } from "react"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import Head from "../components/head"
import useFetch from "../hooks/useFetch"
import {
  getDataFromINIString,
  getMonths,
  getStartingDate,
} from "../scripts/record"
import RecordCard from "../components/recordCard"
import recordStyles from "./record.module.scss"
import { updateScrollCaret } from "../components/stepScroller"
const ScrollReveal = loadable(() => import("../components/scrollReveal"))

const ALL_TIME_URL = "/Cumulus/alltime.ini"
const MONTHLY_URL = "/Cumulus/monthlyalltime.ini"

const RecordPage = () => {
  const [recordsType, setRecordsType] = useState("alltime")
  const [recordsUrl, setRecordsUrl] = useState(ALL_TIME_URL)
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth())

  const { data, isPending, error } = useFetch(recordsUrl)
  //let records = data ? getDataFromINIString(data) : null
  let months = getMonths()

  const parseRecords = data => {
    //console.log(data)
    if (!data) return null
    switch (recordsType) {
      case "alltime":
        return getDataFromINIString(data)
      case "monthly":
        return getDataFromINIString(data, selectedMonth)
      default:
        return null
    }
  }

  let records = parseRecords(data)

  const changeRecordsType = event => {
    let selectedType = event.target.value
    if (selectedType !== recordsType) {
      console.log(selectedType)
      setRecordsType(selectedType)
      updateRecordsUrl(selectedType)
    }
  }

  const updateRecordsUrl = type => {
    switch (type) {
      case "alltime":
        setRecordsUrl(ALL_TIME_URL)
        break
      case "monthly":
        setRecordsUrl(MONTHLY_URL)
        break
      default:
        break
    }
  }

  const getMonthName = index => {
    if (!months || months.length === 0) return ""
    for (const i in months) {
      if (Object.hasOwnProperty.call(months, i)) {
        const month = months[i]
        if (month.index === index) return month.name
      }
    }
    return months[0].name
  }

  useEffect(() => {
    updateScrollCaret()
  }, [data])

  return (
    <Layout>
      <Head title="Record" />
      <div className="container pt-3">
        <ScrollReveal options={{ duration: 1000, origin: "top" }}>
          <div className="btn-group btn-group-toggle" data-toggle="buttons">
            <label className="btn btn-secondary active">
              <input
                type="radio"
                name="recordsTypeRadio"
                value="alltime"
                checked={recordsType === "alltime"}
                onClick={changeRecordsType}
                onChange={changeRecordsType}
              />
              Assoluti
            </label>
            <label className="btn btn-secondary">
              <input
                type="radio"
                name="recordsTypeRadio"
                value="monthly"
                checked={recordsType === "monthly"}
                onClick={changeRecordsType}
                onChange={changeRecordsType}
              />
              Mensili
            </label>
            {recordsUrl === MONTHLY_URL && months ? (
              <div className="btn-group" role="group">
                <button
                  id="monthBtnGroup"
                  type="button"
                  className="btn btn-secondary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {getMonthName(selectedMonth)}
                </button>
                <div className="dropdown-menu" aria-labelledby="monthBtnGroup">
                  {months &&
                    months.map(month => (
                      <button
                        className={`dropdown-item ${
                          month.index === selectedMonth ? "active" : ""
                        }`}
                        key={month.index}
                        onClick={() => setSelectedMonth(month.index)}
                      >
                        {month.name}
                      </button>
                    ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {error && <div className="mb-2 mt-3">{error}</div>}
          {isPending && <div className="mb-2 mt-3">Caricamento dati...</div>}
          {!isPending && !error && (
            <p className="mb-2 mt-3">
              {recordsUrl === ALL_TIME_URL && (
                <span>
                  Registrazioni assolute a partire dal {getStartingDate()}
                </span>
              )}
              {recordsUrl === MONTHLY_URL && months && (
                <span>
                  Registrazioni relative al mese di{" "}
                  {getMonthName(selectedMonth)}
                </span>
              )}
            </p>
          )}
        </ScrollReveal>
        <div className={`card-columns ${recordStyles.cardColumns}`}>
          <ScrollReveal options={{ duration: 1000, origin: "left" }}>
            <RecordCard
              title="Temperatura ed umidità"
              image="/img/temperature.jpg"
              records={records ? records.temperature : null}
            />
          </ScrollReveal>
          <ScrollReveal options={{ duration: 2000, origin: "right" }}>
            <RecordCard
              title="Pioggia"
              image="/img/rain.jpg"
              records={records ? records.rain : null}
            />
          </ScrollReveal>
          <ScrollReveal options={{ duration: 2000, origin: "left" }}>
            <RecordCard
              title="Vento"
              image="/img/wind.jpg"
              records={records ? records.wind : null}
            />
          </ScrollReveal>
          <ScrollReveal options={{ duration: 2000, origin: "right" }}>
            <RecordCard
              title="Pressione"
              image="/img/pressure.jpg"
              records={records ? records.pressure : null}
            />
          </ScrollReveal>
        </div>
      </div>
    </Layout>
  )
}

export default RecordPage
